<template>
  <div>
    <px-capa :banner-capa="header" class="white--text">
      <template v-slot:title>
        Compre as embalagens para sua empresa com a Proxpect
      </template>
      <template v-slot:text>
        Cadastre-se e compre agora, sem precisar de cotações
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        :show-price="false"
        link="/fornecedor/26769"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12" phone-number="5548992120020"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import gridMixin from '@/mixins/grid-mixin';
import RdDuvidaFormSubscription from '../../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from '../LandingPageWhatsapp.vue';
import header from '../../../assets/landing-page/cps/banner desk.png';

import cortadoComTubete from '../../../assets/landing-page/cps/DESK - FILME STRETCH CORTADO COM TUBETE.png';
import cortadoComTubeteMobile from '../../../assets/landing-page/cps/mobile - FILME STRETCH CORTADO COM TUBETE.png';

import preto from '../../../assets/landing-page/cps/desk - filme stretch preto 500x25 com tubete.png';
import pretoMobile from '../../../assets/landing-page/cps/MOBILE - FILME STRETCH PRETO 500X25.png';

import jumbo from '../../../assets/landing-page/cps/FILME STRETCH JUMBO 500 X 25 E 30 MICRA - DESK.png';
import jumboMobile from '../../../assets/landing-page/cps/FILME STRETCH JUMBO 500 X 25 E 30 MICRA - MOBILE.png';

import semTubete from '../../../assets/landing-page/cps/FILME STRETCH 500 X 25 MICRAS SEM TUBETE.png';
import semTubeteMobile from '../../../assets/landing-page/cps/FILME STRETCH 500 X 25 MICRAS SEM TUBETE - MOBILE.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      header,
      query: 'disponivel is true and fornecedor.id = 26769 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos que sua empresa precisa',
      banners: [],
    };
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(cortadoComTubete, cortadoComTubeteMobile),
        link: '/oferta/145276',
      },
      {
        foto: this.getGridImg(preto, pretoMobile),
        link: '/oferta/145277',
      },
      {
        foto: this.getGridImg(jumbo, jumboMobile),
        link: '/oferta/145278',
      },
      {
        foto: this.getGridImg(semTubete, semTubeteMobile),
        link: '/oferta/145271',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    pxComoFunciona: ComoFunciona,
    pxVantagens: Vantagens,
    pxCapa: Capa,
    pxFaqLandingPage: FaqLandingPage,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
